//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductSizes from "@/components/pages/productCard/ProductContent/Components/ProductSizes/index";
import ProductBuyFavorite from "@/components/pages/productCard/ProductContent/Components/ProductBuyFavorite/index";
import ProductPrice from "@/components/pages/productCard/ProductContent/Components/ProductPrice/index";
import ProductPdp from "@/components/pages/productCard/ProductContent/Components/ProductPdp/index";
import SizeAdvice from '~/components/pages/productCard/ProductContent/Components/SizeAdvice';
import {mapGetters} from "vuex";
import monobank from '~/assets/img/icons/monobank.svg'
export default {
    name: "ProductSidebar",
    components: {
        ProductPdp,
        ProductPrice,
        ProductBuyFavorite,
        ProductSizes,
        SizeAdvice,
    },
    props: {
        product: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            monobank: monobank,
        }
    },
    computed: {
        ...mapGetters({
            widgetsDataLoading: 'dynamicPage/getWidgetsDataLoading',
        }),
        productDivision() {
            return (this?.product?.division ?? '')
        },
        productDisplayName () {
            return (this?.product?.displayName ?? '')
        },
        productDefaultColor () {
            return (this?.product?.defaultColor ?? '')
        },
        computedAdviceData(){
            return this.product?.adviceSize ? this.product?.adviceSize : null;
        },
    }
}
